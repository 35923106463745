
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch} from 'vue-property-decorator';
    import {debounce} from 'debounce';

    import {
        IClient,
        ISearchClientsParams,
        IOrganization,
        IDeleteClientParams,
        IUpdateClientParams,
    } from '@/types';

    import {
        ClientModule,
        SnackbarModule,
    } from '@/store/modules';

    const clientNamespace = namespace('client');
    const organizationNamespace = namespace('organization');

    import ClientCard from '@/components/ClientCard.vue';

    @Component<ViewClients>({
        components: {
            ClientCard,
        },
    })
    export default class ViewClients extends Vue {
        public clientId: string = '';
        public clientDialogIsOpen: boolean = false;
        public clientIdToDelete: string|null = null;
        public confirmDeleteDialogIsOpen: boolean = false;
        public confirmBlacklistDialogIsOpen: boolean = false;
        public clientSelected: IClient|null = null;
        public dataTableIsLoading: boolean = true;
        public isFetching = false;
        public headers: object[] = [
          { text: '', value: 'is_editable', align: 'start' },
          { text: 'Prénom / Nom', value: 'full_name', sortable: true },
          { text: 'Contact', value: 'contact' },
          { text: 'Ville', value: 'address' },
          { text: 'N° de dossier', value: 'file_number' },
          { text: 'Fiche externe', value: 'external_link', sortable: false },
          { text: 'Animaux', value: 'animals_count', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false, align: 'start' },
        ];
        public page: number = 1;
        public perPage: number = 10;
        public query: string = '';
        public sortBy: string = 'full_name';
        public sortOrder: string = 'asc';

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;
        @clientNamespace.Getter('clientsList')
        public clients!: IClient[];
        @clientNamespace.Getter('clientsTotal')
        public clientsTotal!: number;

        private debouncedClientQuery = debounce(this.fetchClientsList, 300);

        get mobileHeaders() {
            return this.headers.slice(1, this.headers.length);
        }

        get params(): ISearchClientsParams {
            return {
                organization_id: this.loggedOrganization.id,
                page: this.page,
                per_page: this.perPage,
                query: this.query,
                sort_by: this.sortBy,
                sort_order: this.sortOrder,
            };
        }

        public updatePerPage(e: number) {
            this.perPage = e;
            this.fetchClientsList();
        }
        public updateSortBy(e: string[]) {
            this.sortBy = e[0];
            this.fetchClientsList();
        }
        public updateSortOrder(e: string[]) {
            e[0] ? this.sortOrder = 'desc' : this.sortOrder = 'asc';
            this.fetchClientsList();
        }
        public updatePage(e: number) {
            this.page = e;
            this.fetchClientsList();
        }

        public fetchClientsList() {
            const params: ISearchClientsParams = {
                organization_id: this.loggedOrganization.id,
                page: this.page,
                per_page: this.perPage,
                query: this.query,
                sort_by: this.sortBy,
                sort_order: this.sortOrder,
            };

            getModule(ClientModule, this.$store)
                .fetchClients(params)
                .then(() => {
                    this.dataTableIsLoading = false;
                });
        }

        public openConfirmDeleteDialog(clientId: string) {
            this.clientIdToDelete = clientId;
            this.confirmDeleteDialogIsOpen = true;
        }

        public closeConfirmDeleteDialog() {
            this.clientIdToDelete = null;
            this.confirmDeleteDialogIsOpen = false;
        }

        public openClientDialog() {
            getModule(ClientModule, this.$store).resetClient();

            this.clientId = '';
            this.clientDialogIsOpen = true;
        }

        public editClient(clientId: string) {
            this.clientId = clientId;
            this.clientDialogIsOpen = true;
        }

        public openConfirmBlacklistDialog(client: IClient, status: string) {
            this.confirmBlacklistDialogIsOpen = true;
            this.clientSelected = client;
            this.clientSelected.status = status;
        }

        public animalCreated() {
            this.fetchClientsList();
        }

        public updateStatusClient() {
            if (!this.clientSelected) {
                return;
            }

            this.isFetching = true;

            const params: IUpdateClientParams = {
                client_id: this.clientSelected.id,
                first_name: this.clientSelected.first_name,
                last_name: this.clientSelected.last_name as string,
                email: this.clientSelected.email,
                organization_id: this.loggedOrganization.id,
                file_number: this.clientSelected.file_number,
                external_link: this.clientSelected.external_link,
                address: this.clientSelected.address,
                status: this.clientSelected.status,
            };

            const clientModule = getModule(ClientModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            clientModule
                .updateClient(params as IUpdateClientParams)
                .then((client: IClient) => {
                    this.confirmBlacklistDialogIsOpen = false;
                    this.isFetching = false;
                    snackbarModule.displaySuccess(
                        `${this.clientSelected?.first_name} ${this.clientSelected?.last_name} a bien été modifié(e) !`,
                    );
                });
        }

        public deleteClient() {
            if (!this.clientIdToDelete) {
                return;
            }
            this.isFetching = true;

            const clientModule = getModule(ClientModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            const params = {
                client_id: this.clientIdToDelete,
                organization_id: this.loggedOrganization.id,
            };

            clientModule
                .deleteClient(params as IDeleteClientParams)
                .then(() => {
                    snackbarModule.displaySuccess(
                        `Le client a bien été supprimé !`,
                    );

                    this.confirmDeleteDialogIsOpen = false;
                    this.isFetching = false;
                })
            ;
        }

        public created() {
            getModule(ClientModule, this.$store)
                .fetchClients(this.params)
                .then(() => {
                    this.dataTableIsLoading = false;
                });
        }

        @Watch('query')
        private onQueryChange(val: string) {
            this.page = 1;
            this.debouncedClientQuery();
        }
    }
